import React from 'react'
import { useState } from 'react'
import { TopBanner } from '../components/TopBanner'
import styled from '@emotion/styled'
import dayjs, { Dayjs } from 'dayjs'

import {
  DataGridPro,
  GridRowsProp,
  GridColDef,
  GridValueFormatterParams,
  GridRenderCellParams,
  GridEventListener,
  GridToolbarExport,
  GridToolbarQuickFilter,
  GridToolbarContainer,
  GridToolbar,
} from '@mui/x-data-grid-pro'
import Alert from '@mui/material/Alert'
import { navigate } from 'gatsby'
import { Link } from 'gatsby'
import type { PageProps } from 'gatsby'

import { format, isBefore, isAfter, sub, add, parse } from 'date-fns'

import Grid from '@mui/material/Grid'

import Typography from '@mui/material/Typography'

import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'

import LoginIcon from '@mui/icons-material/Login'
import StartIcon from '@mui/icons-material/Start'
import EventBusyIcon from '@mui/icons-material/EventBusy'
import EventAvailableIcon from '@mui/icons-material/EventAvailable'
import { Button } from '@mui/material'

import { TextField } from '@mui/material'

// ***** GET results on first load, shift through fo search, fetch individual card info when selected (different)
let myCount = 0
//console.log(myCount)

//****TODO: HAUN TSEKKAUS SSN, ADMINOIKAT, HAKU VASTA KUN JOTAIN KIRJOITETTU?   */

const isBrowser = typeof window !== 'undefined'

const Page = ({ location }: PageProps) => {
  function CustomToolbar() {
    return <></>
  }

  const authToken: string =
    (isBrowser ? localStorage.getItem('myauth') : '') || ''
  myCount = myCount + 1

  const params = new URLSearchParams(location.search)
  const viewType = params.get('type')
  //console.log(viewType)
  //console.log('ping: ' + myCount)

  const handleOnClick: GridEventListener<'rowClick'> = params => {
    ;(viewType && viewType === 'view') || params.row.balance === '0.00'
      ? navigate('/view?code=' + params.id)
      : navigate('/use?code=' + params.id)

    //console.log(params.row.balance)
  }

  const [cards, setCards] = useState<any[]>([])
  const [username, setUser] = useState('')

  const [origCount, setOrigCount] = useState(0)
  const [isAdmin, setAdmin] = useState(false)
  const [filterString, setFilterString] = useState('')

  const [errorStatus, setErrorStatus] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const [loading, setLoading] = useState(false)

  const [loaded, setLoaded] = useState(false)
  var myData = []

  const [searchString, setSearchString] = React.useState('')

  const handleSearchStringChange = event => {
    setSearchString(event.target.value as string)
  }

  const handleSearchClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    let value = e.currentTarget.value.trim()

    var searchBody = JSON.stringify({
      search: value,
    })

    //console.log('testi: ' + origCount)

    //console.log('heps: ' + myCount)
    const res = fetch(
      //`https://europe-west1-mehilainen-fi-prod.cloudfunctions.net/getPrepayments`, // TODO: This should be moved to .env
      //`http://localhost:5001/mehilainen-fi-prod/europe-west1/getPrepayments`, // TODO: This should be moved to .env
      `/getPrepayments`,
      {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        headers: {
          authorization: authToken,
          // 'Content-Type': 'application/json',
        },
        body: searchBody,
      }
    )
      .then(async response => {
        //console.log('boing: ' + { response })
        //console.log(response.status)
        setLoaded(true)
        if (!response.ok) {
          //console.log('ei ok ')

          const text = await response.text()
          throw new Error(text)
        }

        if (response.status !== 200 && !origCount) {
          //console.log('ei 200 ')
          if (response.status === 403) {
            const text_1 = await response.text()
            //console.log('onm 403: ' + text_1)
            throw new Error(text_1)
          }
          const text_2 = await response.text()
          //console.log('muuten: ' + text_2)
          throw new Error(text_2)
        } else setErrorStatus(false)

        return response.json()
      })
      .then(data => {
        //console.log('HEEP' + JSON.stringify(data))
        //console.log('dataa')
        setUser(data[0].username)
        setAdmin(data[0].isadmin)

        //console.log(data.username)
        //console.log('*eh')
        //console.log(data.isadmin)

        //console.log('slkdjfslkjf  ' + data)
        const compareDate = data.isadmin
          ? sub(new Date(), {
              days: 5000,
            })
          : sub(new Date(), {
              days: 1,
            })
        //console.log('paiva:   ' + compareDate)
        //var newData = [...myData, data]
        var newData = data

        //console.log(JSON.stringify(newData, null, 2))

        const editedCards = newData.map(item =>
          item['prepaidGuid']
            ? {
                id: item['prepaidGuid'],
                code: item['prepaidGuid'],
                ext: item['externalReferenceId'],
                type:
                  item['code'].trim() === 'DIGLAH'
                    ? 'Lahjakortti'
                    : item['codeDescription'],
                ssn: item['ssn'],
                balance: (0.01 * item['amountUnusedEurocents']).toFixed(2),
                original: (0.01 * item['amountPaidEurocents']).toFixed(2),
                used: (0.01 * item['amountUsedEurocents']).toFixed(2),
                start: dayjs(item['validStart']).format('DD.MM. YYYY'),
                end:
                  item['validEnd'] && item['validEnd'] !== 'null'
                    ? dayjs(item['validEnd']).format('DD.MM. YYYY')
                    : 'ei määritelty',
              }
            : null
        )
        //console.log(JSON.stringify(editedCards, null, 2))

        //  const editedCards = newData.map(item => ({
        //    id: item['prepaidGuid'],
        //    code: item['prepaidGuid'],
        //    ext: item['externalReferenceId'],
        //    balance: (1 * item['amountUnusedAurocents']).toFixed(2),
        //    original: (1 * item['amountPaidEurocents']).toFixed(2),
        //    used: (1 * item['amountUsedEurocents']).toFixed(2),
        //    start: item['validStart'],
        //    end: item['validEnd'],
        //  }))

        //console.log('boing: ' + JSON.stringify(editedCards, null, 2))
        //console.log(data.items)
        setCards(
          editedCards.filter(element => {
            return element !== null
          })
        )

        //setCards(data.items)

        setFilterString(
          data.isadmin
            ? format(
                sub(new Date(), {
                  days: 5000,
                }),
                'yyyy-MM-dd'
              )
            : format(
                sub(new Date(), {
                  days: 3,
                }),
                'yyyy-MM-dd'
              )
        )
        //console.log(data.isadmin + ' pvm: ' + filterString)
        //console.log('ennen datan tuuppaamista: ' + origCount)
        //console.log('pim ' + Object.keys(newData).length)
        ///HUOM!! LAskee väärää mittaa, mieti uusiksi
        setOrigCount(Object.keys(data).length)
      })
      .catch((error: Error) => {
        //console.log(
        //  'erroria ennen: ' +
        //    (!origCount ? 'eka: ' + origCount : 'toka:' + origCount)
        //)
        !origCount ? setErrorStatus(true) : setErrorStatus(false)

        error?.message?.includes('100')
          ? setErrorMessage('Käyttäjätasosi ei riitä')
          : error?.message?.includes('200')
          ? setErrorMessage('Kirjaudu sisään')
          : setErrorMessage('Ongelma') //palauta virheviesti 1, virheviesti 2 tai geneerinen

        //console.log('virhe: ' + error.message)
      })
      .finally(() => setLoading(false))
  }

  const tableColumns: GridColDef[] = [
    {
      headerName: 'ENNAKKOMAKSUN KOODI',
      headerAlign: 'center',
      align: 'left',
      field: 'code',
      sortable: false,
      flex: 1,
    },
    {
      headerName: 'VANHA KOODI',
      headerAlign: 'center',
      align: 'left',
      field: 'ext',
      sortable: false,
      flex: 1,
    },
    {
      headerName: 'KORTIN TYYPPI',
      headerAlign: 'center',
      align: 'left',
      field: 'type',
      sortable: true,
      flex: 1,
    },
    {
      headerName: 'VOIMASSA',
      headerAlign: 'center',
      align: 'center',
      field: 'start',
      sortable: true,
      flex: 1,

      // renderCell: (params: GridRenderCellParams<string>) => <></>,
    },
    {
      headerName: 'VOIMASSA',
      headerAlign: 'center',
      align: 'center',
      field: 'end',
      sortable: true,
      flex: 1,
      //type: 'date',

      //renderCell: (params: GridRenderCellParams<string>) => <></>,
    },

    {
      headerName: 'SALDO',
      headerAlign: 'center',
      align: 'right',
      field: 'balance',
      sortable: true,
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
          return ''
        }

        const valueFormatted = Number(params.value).toFixed(2)
        return `${valueFormatted} €`
      },
    },
    {
      headerName: 'SSN',
      headerAlign: 'center',
      align: 'center',
      field: 'ssn',
      sortable: true,
      flex: 1,
    },
  ]

  const rows: GridRowsProp = [
    cards.map(item => ({
      id: item['id'],

      code: item['code'],
      ext: item['ext'],
      type: item['type'],
      ssn: item['ssn'],
      balance: item['balance'],
      original: item['original'],
      used: item['used'],
      start: dayjs(item['start']).format('YYYY-MM-DD'),
      end: item['end'],
    })),
  ]
  return (
    <>
      <div>
        <TopBanner user={username} />
      </div>
      <div>
        {errorStatus ? (
          <SearchContainer>
            <Alert variant="filled" severity="error" sx={{ width: '100%' }}>
              Virhetilanne: {errorMessage}
            </Alert>

            <LinkContainer>
              <Link to="/">Palaa sisäänkirjautumissivulle</Link>
              <LoginIcon sx={{ ml: 1, color: 'black' }} />
            </LinkContainer>
          </SearchContainer>
        ) : (
          ''
        )}

        <ResultContainer>
          <div>
            <Grid
              container
              justifyContent="left"
              alignItems="center"
              spacing={5}
              columns={18}
              sx={{ paddingTop: 3, paddingBottom: 2, paddingLeft: 1 }}
            >
              <Grid item>
                <Typography variant="body1" color="text.primary">
                  Hae lahjakortin / ennakkomaksun numerolla tai sen osalla,
                  vanhalla koodilla tai asiakkaan henkilötunnuksella
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              columns={18}
              alignItems="center"
              justifyContent="left"
              sx={{ paddingBottom: 3, paddingLeft: 1 }}
            >
              <Grid item>
                <TextField
                  size="small"
                  sx={{ width: 500 }}
                  variant="outlined"
                  onChange={handleSearchStringChange}
                />
              </Grid>
              <Grid item>
                <Button
                  id="search"
                  variant="contained"
                  size="large"
                  value={searchString}
                  onClick={handleSearchClick}
                  disabled={searchString.length < 4}
                  data-value1={searchString}
                >
                  Hae
                </Button>
              </Grid>
            </Grid>
          </div>
          <DataGridPro
            rows={cards}
            columns={tableColumns}
            pagination
            pageSize={20}
            getRowId={row => row.id}
            autoHeight
            density="comfortable"
            disableColumnMenu={true}
            disableColumnFilter={true}
            disableColumnSelector={true}
            disableSelectionOnClick={true}
            autoPageSize
            loading={loading}
            hideFooterRowCount={false}
            components={{ Toolbar: CustomToolbar }}
            //components={{ Toolbar: GridToolbar }}
            //slots={{ toolbar: CustomToolbar }}
            onRowClick={handleOnClick}
            localeText={{
              toolbarDensityComfortable: 'Large',
              noRowsLabel: errorStatus ? 'Virhetilanne ' : 'Ei tuloksia',
              noResultsOverlayLabel: 'Ei tuloksia.',
              //errorOverlayDefaultLabel: 'Tapahtui virhe.',
              toolbarExport: 'Lataa',
              toolbarExportLabel: 'Lataa',
              toolbarExportCSV: 'Lataa CSV-muodossa',
              toolbarExportPrint: 'Tulosta',
              toolbarQuickFilterPlaceholder:
                'Kirjoita hakuteksti (vähintään 3 merkkiä)',
              toolbarQuickFilterLabel: 'Hae',
              toolbarQuickFilterDeleteIconLabel: 'Tyhjennä',
              MuiTablePagination: {
                labelDisplayedRows: ({ from, to, count }) =>
                  ` sivut ${from}-${to}  /${count}`,
              },
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: 'expiration', sort: 'desc' }],
              },
              columns: {
                columnVisibilityModel: {
                  // Hide columns status and traderName, the other columns will remain visible
                  edited: false,
                  recipient: false,
                  ssn: false,
                },
              },
            }}
            sx={{
              '& .MuiDataGrid-row:hover': {
                backgroundColor: '#E9FAF2',
                color: 'primary.main',
              },
              '& .MuiDataGrid-columnHeader': {
                color: 'primary.main',
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: '700',
              },
              '& .MuiDataGrid-cell:focus-within': {
                outline: 'unset',
              },
              '& .MuiDataGrid-columnHeader:focus-within': {
                outline: 'unset',
              },
              '& .MuiDataGrid-toolbarContainer': {
                justifyContent: 'space-between',
                borderBottomWidth: '1px',
                borderBottomStyle: 'solid',
                borderBottomColor: 'rgba(224, 224, 224, 1)',
                '& .MuiInputBase-formControl': {
                  width: '400px',
                  paddingLeft: '5px',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                },

                '& .MuiInput-underline:before': { borderBottom: 'unset' },
                '& input': {
                  paddingLeft: '5px',
                },
              },
            }}
          />
        </ResultContainer>
      </div>
    </>
  )
}

export default Page

const ResultContainer = styled.div`
  max-width: 1100px;
  width: 100%;
  margin: auto;
  padding: 40px;
  text-align: center;
`
const SearchTitleContainer = styled.div`
  margin-bottom: 8px;
  margin-top: 5px;
  padding-top: 5px;
  margin-left: 10px;
  width: 100%;
  text-align: left;
  font-size: 1rem;
`

const SearchContainer = styled.div`
  width: 400px;
  margin: auto;
  padding-top: 15px;
  display: flex;
  text-align: center;
  flex-direction: column;
`

const LinkContainer = styled.div`
  margin: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  align-items: center;
  display: flex;
  color: #eef8ef;
`
